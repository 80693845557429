import React from 'react';
import {Row, Col, Card, Button} from 'antd';

import Layout from '../components/layout';
// import {showMobileAppNoti} from '../helpers/utils';
import FooterBox from '../components/footerBox';

// import apple from '../images/button-app-store-apple.svg';
// import google from '../images/button-app-store-google-play.svg';
import richardImg from '../images/Richard-Reuben.png';
// import michaelImg from '../images/Michael.jpg';
import julieImg from '../images/Julie.png';
import {GET_TICKETTAMER} from '../constant';

// import './about-us.less';

const AboutUsPage = () => {
    return (
    <Layout title="About Us">
        <div className="max-width-container AboutUS">
            <Row justify="center">
                <Col md={12}>
                    <div className="DisplayBlackCenter AboutUs__Title">About Us</div>
                    <div className="LeadingBlackCenter AboutUs__Description">Dedicated professionals excited to help you fix your tickets as fast and easy as possible  </div>
                </Col>
            </Row>
            <Row justify="center">
                <Col md={16}>
                    <div className="H1BlackLeft AboutUs__SubTitle">What We Do for You</div>
                    <div className="LeadingBlackLeft PreText">{`TicketTamer is a website that is designed to make it as easy as possible for you to take care of your traffic ticket and other citations.

We “fix” your ticket by negotiating to reduce the charges down to a non-moving violation to keep the points off your record and your insurance rates down. You will still have to pay a fine and court costs, but you will save hundreds of dollars in higher insurance costs, avoid the hassle of going to court, and keep your driver’s license from being at risk of suspension or revocation.

We charge $75 to fix any traffic ticket. However, there may be additional charges for higher speed traffic tickets and services we may be required to provide by the court, such as your driving record, continuances, court appearances, or clearing a warrant.

TicketTamer is a public service program of ReubenLaw LLC, whose experienced lawyers provide high quality legal services for traffic tickets because we don’t believe governments should use tickets to raise money. `}</div>
<div className="LeadingBlackLeft" style={{paddingTop: 12, paddingBottom: 12}}><i><q>If you find a better quote, we'll beat it!</q></i></div>
<div className="LeadingBlackLeft AboutUs__SubDes PreText mb80">
The managing partner is Richard C. Reuben, who is a law professor at the University of Missouri School of Law in Columbia, Mo. All services performed by Richard C. Reuben are in his capacity as a private citizen, and are not affiliated with the University of Missouri or the University of Missouri School of Law.</div>
                </Col>
            </Row>
            <Row gutter={[{md: 40, xs: 0}, {md: 0, xs: 24}]} justify="center">
                <Col md={{span: 12}} xs={24}>
                    <Card style={{backgroundColor: '#ffe8dc'}}>
                        <div style={{textAlign: 'center', maxWidth: 300, margin: 'auto'}}>
                            <div className="H3BlackCenter AboutUs__BoxTitle mb24">Submit your ticket and get your quote instantly. </div>
                            <a href={GET_TICKETTAMER}><Button type="primary">Get Started</Button></a>
                        </div>
                    </Card>
                </Col>
                {/* <Col md={12} xs={24}>
                    <Card style={{height: '100%', textAlign: 'center'}}>
                        <div className="H3BlackCenter mb8">Get the app for free</div>
                        <Space size={16}>
                            <img src={apple} alt="app store" style={{cursor: 'pointer'}} onClick={showMobileAppNoti} />
                            <img src={google} alt="google" style={{cursor: 'pointer'}} onClick={showMobileAppNoti} />
                        </Space>
                    </Card>
                </Col> */}
            </Row>

            <div className="max-width-container">
                <Row justify="center" className="mt80 mb80">
                    <Col md={16}>
                        <div className="H1BlackLeft">Your TicketTamer Team</div>
                        <div className="Member">
                            <img  src={richardImg} alt="Richard Reuben" className="Member__Ava" />
                            <div>
                                <div className="H2BlackLeft">Richard Reuben</div>
                                <div className="BodyGreyLeft">Founder & Managing Partner</div>
                                <div className="BodyBlackLeft PreText mt24">{`Richard Reuben is the managing partner of ReubenLaw LLC, dba TicketTamer, and the James Lewis Parks Professor of Law and Journalism at the University of Missouri School of Law.

        Richard Reuben has taught at Mizzou since 2000 and has been practicing law since 1991. He has won cases the Missouri Supreme Court and the Missouri Court of Appeals. He has been married for more than 30 years, has two sons, two dogs, two cats, and a 125 gallon fish tank. His hobbies range from ice hockey (Blues and Kings fan) to mindfulness and yoga.

        TicketTamer is not affiliated with the University of Missouri or the University of Missouri School of Law.`}</div>
                            </div>
                        </div>
                        <div className="Member">
                            <img src={julieImg} alt="Julie Chambers" className="Member__Ava" />
                            <div>
                                <div className="H2BlackLeft">Julie Chambers</div>
                                <div className="BodyGreyLeft">Associate of TicketTamer</div>
                                <div className="BodyBlackLeft PreText mt24">{`She graduated from the University of Missouri School of Law in 2009, and served as Editor in Chief of the Journal of Dispute Resolution. She graduated Vanderbilt University in 2005, majoring in communications. She has three children, a dog, and is an avid St. Louis sports fan.`}</div>
                            </div>
                        </div>
                        {/* <div className="Member">
                            <img  src={michaelImg} alt="Michael Ottman" className="Member__Ava" />
                            <div>
                                <div className="H2BlackLeft">Michael Ottman</div>
                                <div className="BodyGreyLeft">Client Services Manager</div>
                                <div className="BodyBlackLeft PreText mt24">{`His experience encompasses a broad spectrum of manufacturing industries, business growth, channel development and SAAS consulting services primarily focused on identifying, establishing and maintaining best practices throughout every aspect of the business.

    Michael has two children and a granddaughter. His hobbies include swimming, exercise, Labrador retriever rescue partner and volunteer dog walking at the local humane society.`}</div>
                            </div>
                        </div> */}
                    </Col>
                </Row>
            </div>
        </div>
        <FooterBox />
    </Layout>
    )
}
export default AboutUsPage;
